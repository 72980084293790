import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
export const useProfileStore = defineStore("profileStore", () => {
  const config = useRuntimeConfig();
  const commonProfileStore = useCommonProfileStore();
  // @ts-ignore
  const { chatRoomId, news, activeNews } = storeToRefs(commonProfileStore);

  const { notify } = useNotification();
  const { t } = useI18n();

  const showDetail = ref<boolean>(false);
  const userBuyoutCodeSupp = ref<string>("");
  const ambassadorStores = ref<AmbassadorStore[]>([]);
  const loadingAmbassadorStores = ref<boolean>(false);
  const localePath = useLocalePath();
  const authStore = useAuthStore();
  const route = useRoute();
  const api = useApi();


  const videos = ref<Video[]>([]);

  function getTypeStateColor (typeState: BuyoutStatus) {
    if (typeState.is_final) {
      return typeState.identifier === "returned" ? "greenTone1" : "redTone1";
    }
    return "orangeTone1";
  }

  function navigateToBuyout (path: string | null, userBuyoutCodeParam: string, partnerOfNewDeviceProcess: string | null = null) {
    const basePath = partnerOfNewDeviceProcess ? localePath({ name: "bonus_partner-buyout-new_device_type-new_meta_master-new_variant-new_color-type-manufacturer-meta_master-variant-color-quality", params: {bonus_partner: partnerOfNewDeviceProcess }}) : localePath({ name: "buyout-type-manufacturer-meta_master-variant-color-quality" });

    if (path) {
      const url = `${config.public.baseUrl}${basePath}/${path}?userBuyoutCode=${userBuyoutCodeParam}`;
      navigateTo(url, { external: true });
    } else {
      if (route.name !== "profile-my-buyouts") { navigateTo(localePath({ name: "profile-my-buyouts" })); }
      showDetail.value = true;
      userBuyoutCodeSupp.value = userBuyoutCodeParam;
    }
  }

  function resetProfileStore () {
    showDetail.value = false;
    userBuyoutCodeSupp.value = "";
    chatRoomId.value = null;
    news.value.educations = false;
    videos.value = [];
  }

  function resetBuyoutDetail () {
    showDetail.value = false;
    userBuyoutCodeSupp.value = "";
    chatRoomId.value = null;
  }


  function updateIsAccessed (fileId: string | null = null, videoUrl: string | null = null) {
    if (fileId) {
      const item = videos.value.find(video => video.id === fileId);
      if (item) {
        item.is_accessed = true;
      }
    } else if (videoUrl) {
      videos.value.forEach((video) => {
        if (video.url === videoUrl) {
          video.is_accessed = true;
        }
      });
    }
    videosUpdated(videos.value);
  }
  async function markFileAsAccessed (file: Video) {
    if (file.is_accessed) { return; }

    const { error } = await api.call<MarkedAsAccessedResponse>(api.commonUrls.auth.MARK_AS_ACCESSED(), {
      method: "POST",
      body: {
        file_uuid: file.id
      }
    });
    if (error.value) {
      useHandleError(error.value);
    } else {
      updateIsAccessed(file.id);
    }
  }

  function videosUpdated (videos: Video[]) {
    news.value.educations = videos.some(video => !video.is_accessed);

    if (!news.value.educations) {
      return;
    }

    videos.sort((a, b) => {
      if (a.is_accessed && !b.is_accessed) {
        return 1;
      } else if (!a.is_accessed && b.is_accessed) {
        return -1;
      }
      return 0;
    });
  }

  async function fetchAmbassadorStores() {
    loadingAmbassadorStores.value = true;
    const { data, error } = await api.call<AmbassadorStore[] | null>(api.commonUrls.auth.GET_AMBASSADOR_STORES(authStore.data?.partner.id ?? ""));


    if (data.value) {
      loadingAmbassadorStores.value = false;
    }

    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: error.value!.data?.detail ?? t("general.error_sending_request"),
        type: "error"
      });
    }
    loadingAmbassadorStores.value = false;
    return data.value ?? [];
  }

  async function loadAmbassadorStores () {
    ambassadorStores.value = await fetchAmbassadorStores();
  }

  return {
    activeNews,
    news,
    chatRoomId,
    videos,
    fetchAmbassadorStores,
    // @ts-ignore
    fetchChatRoom: commonProfileStore.fetchChatRoom,
    // @ts-ignore
    getNews: commonProfileStore.getNews,
    getTypeStateColor,
    navigateToBuyout,
    showDetail,
    ambassadorStores,
    userBuyoutCodeSupp,
    loadingAmbassadorStores,
    resetProfileStore,
    loadAmbassadorStores,
    markFileAsAccessed,
    resetBuyoutDetail,
    updateIsAccessed,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot));
}
