import {acceptHMRUpdate, defineStore} from "pinia";

export const useCommonProfileStore = defineStore("commonProfileStore", () => {
  const { $api } = useNuxtApp() as { $api: IApiInstance };
  const {notify} = useNotification();
  const {t} = useI18n();
  const authStore = useAuthStore();

  const chatRoomId = ref<string | null>(null);

  const news = ref<News | null>(null);

  const activeNews = computed<boolean>(() => {
    return Object.values(news.value || {}).includes(true) && authStore?.isLoggedIn;
  });

  function resetCommonProfileStore() {
    chatRoomId.value = null;
  }

  async function fetchChatRoom() {
    const {data, error} = await $api.user.chatRoom();
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    } else if (data.value) {
      chatRoomId.value = data.value.id;
    }
  }

  async function getNews() {
    const {data, error} = await $api.user.getNews();
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    if (data.value) {
      news.value = data.value;
    }
  }

  async function hasReportedProblems() {
    const {data, error} = await $api.businessCase.listBusinessCaseIssues(1, 1);

    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("client_zone.data_load_error"),
        type: "error",
      });
      return false;
    }

    return data.value && data.value.count > 0;
  }


  return {
    chatRoomId,
    resetCommonProfileStore,
    fetchChatRoom,
    news,
    activeNews,
    getNews,
    hasReportedProblems
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommonProfileStore, import.meta.hot));
}
